import React from "react";
import NavBar from "./Components/navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Solution from "./Components/Solution";
import Products from "./Components/Products";
import Contact from "./Components/Contact";
import PrivacyPolicy from "./Components/PrivacyPolicy";

const App = () => {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Solution" element={<Solution />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
