import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight  } from "@fortawesome/free-solid-svg-icons";
import pharmacy from '../assets/finance.jpg'
import hs from '../assets/supply.jpeg'
import dental from '../assets/pharm.jpg'
import Frontend from '../assets/io.webp'
import Backend from '../assets/kljlk.webp'
import manitor from '../assets/ttyuyty.webp'


const HomeContent = () => {
  return (
     <div className="container mx-auto px-4 py-8">
    
      <div className="flex flex-col md:flex-row items-center justify-between mb-8">
        <div className="w-full md:w-1/2 pr-0 md:pr-4 mb-4 md:mb-0">
          <img src={manitor} alt='logo' className=" sm:w-full  md:w-auto h-auto " style={{height:'400px', width:'600px'}}/>
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-4 text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">Monitor All Your Assets</h2>
          <p className="text-gray-700 m-4">
            Simplify the way your assets move and perform from a single dashboard. Go beyond basic track-and-trace with a power-packed combination of vehicle tracking, performance scorecards, insightful reports, and real-time alerts.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between mb-10">
        <div className="w-full md:w-1/2 pr-0 md:pr-4 text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">Protect Your Vehicles and Cargo</h2>
          <p className="text-gray-700 m-4">
            Keep your vehicles, cargo, and drivers safe with the power of AI and IoT. Combine advanced video telematics, state-of-the-art sensors, remote immobilization, and SOS alerts to be your own fleet safety officer.
          </p>
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-4">
          <img src={Backend} alt='logo' className="w-full md:w-auto h-auto" style={{height:'400px', width:'600px'}}/>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between mb-10">
        <div className="w-full md:w-1/2 pr-0 md:pr-4">
          <img src={Frontend} alt='logo' className="w-full md:w-auto h-auto" style={{height:'400px', width:'600px'}} />
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pl-4 text-center">
          <h2 className="text-4xl font-bold  text-gray-800 mb-4">Manage Your Drivers and Their Potential</h2>
          <p className="text-gray-700 m-4">
            Gain real-time visibility into driver behavior and productivity. Leverage ADAS, DMS, gamified scorecards, geofencing, and trip management to improve driver retention and performance.
          </p>
        </div>
      </div>

      <div className="text-center mb-8">
        <p>DIGITAL TRANSFORMATION FOR ALL SECTORS</p>
        <h1 className="text-4xl font-bold text-gray-800">
        100,000+ Happy Customers Across 25+ Industries
        </h1>
      </div>
      <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-1/3 px-4 mb-8" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
  <div className="bg-white shadow-lg rounded-lg overflow-hidden" style={{ flex: '1 1 auto' }}>
    <img src={hs} alt="Web Application" className="w-full h-64 object-cover object-center" />
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-2">Supply Chain & Logistics</h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      {/* <p>Technology Used:</p>
      <p>
        React JS, Node JS, Express JS & My sql
      </p> */}
      {/* <Link to="/" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link> */}
    </div>
  </div>
</div>


        <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={dental} alt="Mobile App" className="w-full h-64 object-cover object-center" />
            <div className="p-4">
            <h2 className="text-xl font-semibold mb-2">Pharmaceutical </h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      {/* <Link to="/" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>       */}
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={pharmacy} alt="UI/UX Design" className="w-full h-64 object-cover object-center" />
            <div className="p-4">
            <h2 className="text-xl font-semibold mb-2">Vehicle Leasing & Finance </h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      {/* <Link to="/" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>      */}
            </div>
          </div>
        </div>
        {/* <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={pharmacy} alt="UI/UX Design" className="w-full h-64 object-cover object-center" />
            <div className="p-4">
            <h2 className="text-xl font-semibold mb-2">Stock Management System</h2>
      <p className="text-gray-700">
       Workflow Management
      </p>
      <Link to="/web_application" className="mt-4 block w-full text-center bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
      <FontAwesomeIcon icon={faArrowRight} className="text-white" />

              </Link>     
            </div>
          </div>
        </div> */}
      </div>
      
    </div>
  );
};

export default HomeContent;
