import React from "react";
import logo from "../assets/5y.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <div className="mx-auto py-8 text-white font-serif" style={{ backgroundColor: "rgb(1, 6, 27)" }}>
     <style>{`
    @media (max-width: 600px) {
      .test {
        display: none; 
      }
    }
    `}</style>
    <div style={{ backgroundColor: "rgb(9, 30, 62)" }}>
      <div className="flex flex-col md:flex-row pt-10 px-4 md:px-0">
        <div className="w-full md:w-1/5 ml-0 md:ml-10 mb-4 md:mb-0">
          <h2 className="mb-2 text-bold text-2xl">Products</h2>
          <ul>
            <li>Fleet Management Solution</li>
            <li>Fuel Monitoring Solution</li>
            <li>Video Telematics Solution</li>
          </ul>
        </div>
        <div className="test w-full md:w-1/5 mb-4">
          <h2 className="mb-2 text-2xl">Technologies</h2>
          <ul>
            <li>JavaScript</li>
            <li>React JS</li>
            <li>Tailwind, Bootstrap</li>
            <li>Node JS, Express JS</li>
            <li>MySQL, MongoDB</li>
            <li>Python</li>
            <li>AWS</li>
          </ul>
        </div>
        <div className="w-full md:w-1/5 mb-4">
          <h2 className="mb-2 text-2xl">Company</h2>
          <ul>
            <li>About Us</li>
            <li>Contact us</li>
            <li>Careers</li>
            <li>Partner with us</li>
          </ul>
        </div>
        <div className="w-full md:w-1/3 mb-4 ">
          <div style={{ width: "450px" }}>
          <div className="flex items-center ml-8">
          <img
            src={logo}
            alt="Logo"
            className="logo mt-2 sm:h-14 h-8"
            style={{ borderRadius: "50%" }}
          />
          <div className="mt-2 pl-2" style={{ textAlign: "center" }}>
            <h2 className="font-semibold text-xl text-white">
              FIVE Y TECHNOLOGIES
            </h2>
            <h2 className="font-semibold text-lg" style={{ color: "#EF4466" }}>
              D E C O D E R S
            </h2>
          </div>
        </div>
          </div>
          <div className="mb-4 mt-4" style={{ width: "450px" }}>
            Your digital journey begins here, where pixels meet purpose and code turns dreams into reality.
            We aim to deliver best-in-class fleet management solutions to businesses everywhere. Be a part of this Five Y Tech Collective and join our mission to democratise access to fleet technology across the globe.
          </div>
          <div className="flex justify-start">
            <div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
              <a href="https://www.facebook.com/people/Five-Y-Technologies-Decoders/61554694599414/?mibextid=ZbWKwL" className="mr-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} className="w-10 h-6" />
              </a>
            </div>
            <div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
              <a href="https://www.linkedin.com/company/fiveytech/" className="mr-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="w-10 h-6" />
              </a>
            </div>
            <div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
              <a href="https://www.instagram.com/fiveytechnologies/?igshid=ODA1NTc5OTg5Nw%3D%3D" className="mr-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} className="w-10 h-6" />
              </a>
            </div>
            <div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
              <a href="https://www.youtube.com/@FiveYTechnologies" className="mr-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} className="w-10 h-6" />
              </a>
            </div>
            <div className="rounded-full border-2 border-gray-300 p-1 mr-2 hover:bg-blue-500 hover:border-blue-500 transition duration-300 ease-in-out">
              <a href="https://twitter.com/fiveytech?t=dps8Wn6cdWZw2HIM5PNckg&s=08" className="mr-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} className="w-10 h-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center  p-5">
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
            <span>K.K.Nagar, Madurai-625020</span>
          </div>
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faPhone} className="mr-1" />
            <span>+91 6369070815</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
            <span>info@fiveytech.com</span>
          </div>
        </div>
      </div>
    </div>
    <div className="flex justify-center border-t pt-4">
      <div className="flex items-center">
        <p className="text-gray-500 text-sm">&copy; 2024 Five Y Technologies All Rights Reserved</p>
      </div>
    </div>
  </div>
);

export default Footer;
