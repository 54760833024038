import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/5y.jpeg";
// import logo from '../assets/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
  faTruck,
  faMobileAlt,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import webAppImage from "../assets/JKOJ.jpg";

const NavBar = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [isHovering1, setIsHovering1] = useState(false);

  const [isHoverContentHovering, setIsHoverContentHovering] = useState(false);
  const [isHoverContentHovering1, setIsHoverContentHovering1] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleHoverIn = () => {
    setIsHovering(true);
  };

  const handleHoverOut = () => {
    setTimeout(() => {
      setIsHovering(false);
    }, 500);
  };

  const handleHoverIn1 = () => {
    setIsHovering1(true);
  };

  const handleHoverOut1 = () => {
    //  setTimeout(() => {
    setIsHovering1(false);
    // }, 100);
  };
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>

    <style>{`
    @media (max-width: 600px) {
      .test {
        display: none; 
      }
    }
    `}</style>
      <header
        className="navbar flex justify-between items-center text- border-b px-6 sticky top-0 z-10 bg-gray-50"
        // style={{ backgroundColor: "rgb(1, 6, 27)"}}
      >
        <div className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="logo mt-2 sm:h-14 h-8"
            style={{ borderRadius: "50%" }}
          />
          <div className="mt-2 pl-2" style={{ textAlign: "center" }}>
            <h2 className="font-semibold text-xs text-green-900">
              FIVE Y TECHNOLOGIES
            </h2>
            <h2 className="font-semibold text-xs" style={{ color: "#EF4466" }}>
              D E C O D E R S
            </h2>
          </div>
        </div>

        <div className="block sm:hidden">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            {isMobileMenuOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Desktop menu */}
        <ul className="hidden sm:flex font-serif">
          <Link to="/">
            <li className="p-4 hover:border-b-2 cursor-pointer">
              <b>Home</b>
            </li>
          </Link>

          {/* <Link to="/Solution">
            {" "}
            <li
              className="p-4 hover:border-b-2 cursor-pointer"
              onMouseEnter={handleHoverIn}
              onMouseLeave={handleHoverOut}
              onClick={() => setIsHovering(false)}
            >
              <b>Solution</b>
            </li>{" "}
          </Link>

          <Link to="/Products">
            <li
              className="p-4 hover:border-b-2 cursor-pointer"
              onMouseEnter={handleHoverIn1}
              onMouseLeave={handleHoverOut1}
              onClick={() => setIsHovering1(false)}
            >
              <b>Products</b>
            </li>
          </Link>

          <Link to="/Contact">
            {" "}
            <li className="p-4 hover:border-b-2 cursor-pointer">
              <b>Contact</b>
            </li>
          </Link> */}

          <Link to="/PrivacyPolicy">
            <li className="p-4 hover:border-b-2 cursor-pointer">
              <b>Privacy Policy</b>
            </li>
          </Link>
        </ul>
        <ul
          className={`sm:hidden absolute top-full left-0 right-0 bg-gray-50 border-t border-gray-300 ${
            isMobileMenuOpen ? "block" : "hidden"
          }`}
        >
          <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>
            <li className="p-4 hover:border-b-2 cursor-pointer">
              <b>Home</b>
            </li>
          </Link>
          {/* <Link to="/Solution" onClick={() => setIsMobileMenuOpen(false)}>
          <li className='p-4 hover:border-b-2 cursor-pointer'><b>Solution</b></li>
        </Link>
        <Link to="/Products" onClick={() => setIsMobileMenuOpen(false)}>
          <li className='p-4 hover:border-b-2 cursor-pointer'><b>Products</b></li>
        </Link>
        <Link to="/Contact" onClick={() => setIsMobileMenuOpen(false)}>
          <li className='p-4 hover:border-b-2 cursor-pointer'><b>Contact</b></li>
        </Link> */}
          <Link to="/PrivacyPolicy" onClick={() => setIsMobileMenuOpen(false)}>
            <li className="p-4 hover:border-b-2 cursor-pointer">
              <b>Privacy Policy</b>
            </li>
          </Link>
        </ul>
        {/* <ul className="flex font-serif ">
        <Link to="/"><li className='p-4 hover:border-b-2 cursor-pointer'><b>Home</b></li></Link>
          <Link to="/Solution" >  <li className='p-4 hover:border-b-2 cursor-pointer' onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} onClick={() => setIsHovering(false)}>
          
              <b >Solution</b>
           
          </li> </Link>
        
          <Link to="/Products" ><li className='p-4 hover:border-b-2 cursor-pointer' onMouseEnter={handleHoverIn1} onMouseLeave={handleHoverOut1} onClick={() => setIsHovering1(false)}><b>Products</b></li></Link>
        
          <Link to="/Contact"> <li className='p-4 hover:border-b-2 cursor-pointer'><b>Contact</b></li></Link>
          <Link to="/PrivacyPolicy"> <li className='p-4 hover:border-b-2 cursor-pointer'><b>PrivacyPolicy</b></li></Link>
        </ul> */}
      </header>

      <header
        className="test flex justify-between items-center  px-6  top-0 z-10 "
        style={{
          boxShadow: "inset 0 0 10px 0px rgba(0, 0, 0, 1)",
          fontFamily: "serif",
        }}
      >
        <div className="flex  mb-1">
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
            <span>K.K.Nagar, Madurai-625020</span>
          </div>
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faPhone} className="mr-1" />
            <span>+91 6369070815</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
            <span>info@fiveytech.com</span>
          </div>
        </div>
        <div className="flex flex-row ">
          <div className="text-3xl font-bold  text-gray-700 mb-2">
            <a
              href="https://www.facebook.com/people/Five-Y-Technologies-Decoders/61554694599414/?mibextid=ZbWKwL"
              className="mr-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} className="w-10 h-6 " />
            </a>
          </div>
          <div className="text-3xl font-bold  text-gray-700 mb-2">
            <a
              href="https://www.linkedin.com/company/fiveytech/"
              className="mr-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} className="w-10 h-6" />
            </a>
          </div>
          <div className="text-3xl font-bold  text-gray-700 mb-2">
            <a
              href="https://www.instagram.com/fiveytechnologies/?igshid=ODA1NTc5OTg5Nw%3D%3D"
              className="mr-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="w-10 h-6 " />
            </a>
          </div>
          <div className="text-3xl font-bold  text-gray-700 mb-2">
            <a
              href="https://www.youtube.com/@FiveYTechnologies"
              className="mr-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} className="w-10 h-6 " />
            </a>
          </div>
          <div className="text-3xl font-bold  text-gray-700 mb-2">
            <a
              href="https://twitter.com/fiveytech?t=dps8Wn6cdWZw2HIM5PNckg&s=08"
              className="mr-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} className="w-10 h-6 " />
            </a>
          </div>
        </div>
      </header>

      {(isHovering || isHoverContentHovering) && (
        <div
          className="hover-content text-center sticky z-10 ml-10 mr-10"
          style={{ top: "79px" }}
          onMouseEnter={() => setIsHoverContentHovering(true)}
          onMouseLeave={() => setIsHoverContentHovering(false)}
        >
          <div
            className="p-14 text-center absolute z-50 top-30 w-full bg-white grid grid-cols-2"
            style={{
              boxShadow: "inset 0 0 10px 5px rgba(0, 0, 0, 1)",
              fontFamily: "serif",
            }}
          >
            <div className="">
              <img src={webAppImage} alt="Your Image" />
            </div>
            <div className="pt-8">
              <h2 className="text-3xl font-bold mb-2">Our Solution</h2>
              <br />
              <p
                className="text-gray-700 hover:text-blue-600"
                onClick={() => setIsHoverContentHovering(false)}
              >
                <Link to="/">
                  {" "}
                  <FontAwesomeIcon icon={faTruck} /> Fleet Management Solution
                </Link>
              </p>
              <br />
              <p className="text-gray-700">
                <FontAwesomeIcon icon={faTruck} /> Fuel Monitoring Solution
              </p>
              <br />
              <p className="text-gray-700">
                <FontAwesomeIcon icon={faTruck} /> Vedio Telematics Solution
              </p>
            </div>
          </div>
        </div>
      )}

      {/* {(isHovering1 || isHoverContentHovering1) && (
        <div 
          className="hover-content text-center sticky z-10  ml-10 mr-10" style={{top:'79px'}}
          onMouseEnter={() => setIsHoverContentHovering1(true)}
          onMouseLeave={() => setIsHoverContentHovering1(false)}
        >
          
          <div className="bg-gray-200 p-4 text-center absolute z-50 top-30 w-full">
            <h2 className="text-xl font-bold mb-2">Our Products</h2>
            <p className="text-gray-700">AAAAAA</p>
            <p className="text-gray-700">BBBBBB</p>
            <p className="text-gray-700">CCCCCCC</p>
          </div>
        </div>
      )}
       */}
    </>
  );
};

export default NavBar;
