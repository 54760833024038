import React from "react";
import TEST from "../assets/try.gif";
import Footer from "./footer";
import HomeContent from "./HomeContent";

const Home = () => (
  <div className="font-serif">
    <style>
      {`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in {
          animation: fade-in 1s ease-out;
        }

        
      `}
    </style>
    <div
      className="navbar flex flex-col sm:flex-row   animate-fade-in"
      style={{ height: "500px", backgroundColor: "rgb(1, 6, 27)" }}
    >
      <div className="w-full sm:w-1/2">
        <img
          src={TEST}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            maskImage:
              "linear-gradient(to left, transparent, black 20%, black 90%, transparent)",
            WebkitMaskImage:
              "linear-gradient(to left, transparent, black 50%, black 90%, transparent)",
          }}
        />
      </div>

      <div className="w-full sm:w-1/2 flex flex-col justify-center items-center font-serif text-center">
        <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-white ">
          The Future of Fleet Operations Management is Here
        </h2>
        <p className="text-base sm:text-lg leading-relaxed text-center text-white p-8 sm:pr-0">
          Single platform to track, monitor, and manage your vehicles, assets,
          fuel, expenses and more.
        </p>
        <div className="mt-8">
          <button className="py-2 px-4 rounded-full bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800 text-white">
            Get Started
          </button>
        </div>
      </div>
    </div>

    <HomeContent />
    <Footer />
  </div>
);

export default Home;
